import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import unitOpOptions from "./UnitOps.js";
import sampleLocationOptions from "./SampleLocations.js";
import analyteOptions from "./Analytes.js";
import ProcessAreaArr from "./ProcessAreaArr.js";
import SelectorsTablePlot from "./SelectorsTablePlot.js"; // Assuming this is the updated Selectors component
import CreateTable from "./CreateTable.js";
import CreatePlot from "./CreatePlot.js";
import { useParams } from "react-router-dom";
import "./Page.css";
import FeedbackForm from "./FeedBackForm.js";

/**
 * Abstract Page defines both the layout and the sub components initialized when a labdash page is rendered.
 *  - uses CreatePlot() -> generate plots
 *  - if no data is passed this will still run
 *  - When No Data Passed
        - Maps Last 3 hours
    - None is defined at the top of all 3 static files
    - the static files could be joined into a single json structure to simplify this
    - currently users can render any combination of the analytes and sample locations
    - all input parameters have a "_"+variableName
 * @param {*} param0
 * @returns
 */
const AbstractPage = ({
  initialUnitOp,
  initialProcessLocation,
  initialSampleLocation,
  initialAnalyte,
  initialStartTime,
  initialEndTime,
}) => {
  const [unitOp, setUnitOp] = useState(initialUnitOp);
  const [processLocation, setProcessLocation] = useState(initialProcessLocation);

  // Use the useEffect hook to re-render the component when initialUnitOp or initialProcessLocation change
  useEffect(() => {
    setUnitOp(initialUnitOp);
    setProcessLocation(initialProcessLocation);
  }, [initialUnitOp, initialProcessLocation]);

  return (
    <>
      <div className="your-component-container">
        <SelectorsTablePlot initialProcessLocation={processLocation} initialUnitOp={unitOp} />
        <br />
        <FeedbackForm />
      </div>
    </>
  );
};

AbstractPage.propTypes = {
  initialUnitOp: PropTypes.any,
  initialProcessLocation: PropTypes.any,
  initialSampleLocation: PropTypes.any,
  initialAnalyte: PropTypes.any,
  initialStartTime: PropTypes.any, // Changed to string since input type is datetime-local
  initialEndTime: PropTypes.any, // Changed to string since input type is datetime-local
};

export default AbstractPage;
