const Analytes = [
  "% Ash",
  "% Moisture",
  "% Sugar",
  "Alkalinity",
  "Brix",
  "CaO",
  "Color",
  "Conductivity",
  "CV",
  "Lime Salts",
  "MA",
  "pH",
  "Purity",
  "RDS",
  "Screen +35",
  "Screen +65",
  "Screen -100",
  "SO2",
  "Loads",
  "% Ignition Loss",
  "% Invert",
  "% Moist",
  "% Purity",
  "% Raffinose",
  "% Stm/Bts",
  "% Sugar + Steam",
  "% Sugar - Steam",
  "Aff Sugar",
  "App Pur",
  "Avail CaO",
  "B Pad",
  "Back",
  "Black Sediment",
  "Boil Time",
  "CaO/Sug",
  "Cl",
  "Clarity/Turb",
  "CO",
  "CO2",
  "CO2 %Abs",
  "Comp +20",
  "Comp +30",
  "Comp +40",
  "Comp +50",
  "Comp +60",
  "Comp +70",
  "Comp +80",
  "Comp -100",
  "Comp CV",
  "Comp MA",
  "Cond Sugar",
  "Cuft",
  "CWT",
  "CWT/HR",
  "Dummy",
  "Durability",
  "Exit Alkalinity",
  "Exit Temp 'C",
  "Factor",
  "Fillmass Cuft",
  "Fines",
  "from Kiln, Tons",
  "Glutamate",
  "Hardness",
  "Hi Raw Sugar",
  "IR % Moist",
  "Kiln Tons",
  "Lactic Acid",
  "Length",
  "MDS",
  "Metal CNT",
  "Number",
  "Number of",
  "O2",
  "Opt Alk",
  "pH-A",
  "PO4",
  "ppm Sugar",
  "Purity-A",
  "Quarts",
  "RDS-A",
  "Slaker, Tons",
  "SO3",
  "Sugar Color",
  "Sugar Purity",
  "Sugar Traces",
  "Sulfur",
  "Supply Temp 'C",
  "Synth Purity",
  "Temp",
  "Temp 'C",
  "Thin Juice To Stor",
  "Thin Juice from Stor",
  "Titer CaO",
  "Tons",
  "Tons/Hr",
  "Total Cuft",
  "Truck Loaded",
  "True Purity",
  "Turb",
  "Up",
  "V Speck",
  "W Pad",
  "Wgt/Cuft",
  "White Sediment",
  "None",
];

export default Analytes;
