import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import "../../layouts/dashboards/LabDash/Page.css";

/**
 * Renders button for logging in (either redirect or popup depending on input text)
 * Note the [useMsal] package
 */

export const SignInButton = (loginType) => {
  const { instance } = useMsal();
  const handleLogin = (loginType) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e) => {
        console.log(e);
      });
    }
  };
  return (
    <>
      <button className="sign-button" onClick={() => handleLogin("redirect")}>
        Sign in
      </button>
    </>
  );
};
