const SampleLocations = [
  "Evap Thick Juice",
  "Hi Raw Melter",
  "Std Liquor",
  "Hi Raw Storage",
  "Low Raw Storage",
  "Mol Produced",
  "White Pan",
  "Aff Fillmass",
  "Affination Syr",
  "Evap Thick Juice",
  "Hi Raw Pan",
  "Hi Raw Storage",
  "Low Raw",
  "Low Raw Storage",
  "Sug Produced",
  "Thk Jce fr Stor",
  "White Sugar",
];
export default SampleLocations;
