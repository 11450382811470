import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LabSheetFacility from "./LabSheetsFacility";
import LabSheetBeetEnd from "./LabSheetsBeetEnd";
import LabSheetSugarEnd from "./LabSheetsSugarEnd";
import LabDashNavBar from "../LabDashNavBar";
import "../Page.css";
import FeedbackForm from "../FeedBackForm.js";

/**
 * Abstract Page defines both the layout and the sub components initialized when a labdash page is rendered.
 *  - uses CreatePlot() -> generate plots
 *  - if no data is passed this will still run
 *  - When No Data Passed
        - Maps Last 3 hours
    - None is defined at the top of all 3 static files
    - the static files could be joined into a single json structure to simplify this
    - currently users can render any combination of the analytes and sample locations
    - all input parameters have a "_"+variableName
 * @param {*} param0
 * @returns
 */
const AbstractPage = () => {
  return (
    <>
      <div className="your-component-container">
        <div style={{ width: "1100px" }}>
          <LabDashNavBar />
        </div>
        <br />
        <LabSheetSugarEnd />
        <br />
        <FeedbackForm />
      </div>
    </>
  );
};

export default AbstractPage;
