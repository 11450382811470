// COPYRIGHT VESL, LLC 2023
// CONFIDENTIAL DO NOT DISTRIBUTE WITHOUT EXPRESS PERMISSION OF VESL OWNERSHIP
/* ____________________________________________________________________________________________________
COPYRIGHT VESL ENGINEERING 2023
CONFIDENTIAL DO NOT DISTRIBUTE WITHOUT EXPRESS PERMISSION OF VESL OWNERSHIP
The purpose of this component is to render the filters as well as the graphs desired on the base UI.
Use of react components to render the pieces correctly
Use of D3 to create all plots
Use of Material UI and Bootstrap for basic Styling of objects.
_______________________________________________________________________________________________________*/

// VESL COMPONENT SPECIFIC CUSTOMIZATION / IMPORTS
// IN PROCESS remove import dataJSON to use api data
//    import dataJSON from "../data/dataJSON.json";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton, // Added import statement for IconButton
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import unitOpOptions from "./UnitOps.js";
import sampleLocationOptions from "./SampleLocations.js";
import sampleLocationCommonOptions from "./SampleLocationsCommon.js";
import analyteOptions from "./Analytes.js";
import analyteCommonOptions from "./AnalytesCommon.js";
import ProcessAreaArr from "./ProcessAreaArr.js";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import { setCommentRange } from "typescript";
import { data } from "jquery";
import ProcessLoc2UnitOp from "./ProcessLoc2UnitOp.js";
import UnitOp2SampleLoc from "./UnitOp2SampleLoc.js";

// TODO modify this to read sql table via json
// TODO modify so it reads the
const CreateTable = ({
  initialProcessLocation,
  initialUnitOp,
  initialSampleLocation,
  initialAnalyte,
  initialStartTime,
  initialEndTime,
  countKey,
}) => {
  const [inputData, setInputData] = useState([0]);
  const [inputHeader, setInputHeader] = useState([]);
  //const [location, setLocation] = useState("https://localhost:5001/api/Samples");
  const [newRow, setNewRow] = useState({});
  const [addSampleLocation, setAddSampleLocation] = useState(initialSampleLocation);
  const [addAnalyte, setAddAnalyte] = useState(initialAnalyte);
  const [addCollectDT, setAddCollectDT] = useState(new Date());
  const [addSampleTestDT, setAddSampleTestDT] = useState(new Date());
  const [addUOM, setAddUOM] = useState("Units");
  const [addResultValue, setAddResultValue] = useState();
  const [addUpperLimit, setAddUpperLimit] = useState(0);
  const [addLowerLimit, setAddLowerLimit] = useState(100);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set the initial number of rows per page
  const [addCommonSampleLocation, setAddCommonSampleLocation] = useState(initialSampleLocation);
  const [addCommonAnalyte, setAddCommonAnalyte] = useState(initialAnalyte);
  const [addCommonResultValue, setAddCommonResultValue] = useState();

  //const [inputData, setInputData] = useState([]); // dataset
  //const [inputHeader, setInputHeader] = useState([]); // dataset
  const [updatedData, setUpdatedData] = useState([]);
  // STATE VARIABLES FOR HTTP REQUESTS
  const [httpReqError, setHttpReqError] = useState(null); // errors importing or modifying dataset
  const [loadingData, setLoadingData] = useState(null); // is loading complete?
  const [apiBaseUrl, setApiBaseUrl] = useState(null);
  const [apiRouteExtension, setApiRouteExtension] = useState(null);
  const [apiRoute, setApiRoute] = useState(null);
  const [apiHeaders, setApiHeaders] = useState(null); // headers for loading data
  const [sampleLocationArray, setSampleLocationArray] = useState(sampleLocationOptions);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  var sampleLocationString = getSampleLocations("all");
  const filterData = require("./filterJSON");
  const filterDataArray = require("./filterJSONArray.js");
  const placeHolder = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    fetchData_SampleTbl();
  }, []);

  useEffect(() => {
    fetchData_SampleTbl();
  }, [countKey, initialUnitOp, initialProcessLocation]);

  // Synchronous Call
  const fetchData_SampleTbl = async () => {
    if (initialUnitOp !== null && initialUnitOp !== "None") {
      sampleLocationString = getSampleLocations(initialUnitOp);
    } else if (initialProcessLocation !== null && initialProcessLocation !== "None") {
      sampleLocationString = getSampleLocations(initialProcessLocation);
    }

    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/Samples/ByLocationArr?sampleLocations=${sampleLocationString}`,
        {
          timeout: 200000,
          headers: {
            crossDomain: true,
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            //Referer: "https://vesl.tech",
          },
        }
      );
      let dataArr = response.data;
      dataArr = filterData(
        dataArr,
        initialAnalyte,
        initialSampleLocation,
        initialStartTime,
        initialEndTime,
        false
      );
      // Hopefully everything is working by now.
      // Assuming you called this stuff in the same type as you did here (ie a try statement inside of async function)
      dataArr.sort((a, b) => b.ID - a.ID);
      dataArr.reverse();
      setInputData(dataArr);
      setInputHeader(Object.keys(dataArr[0]));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getSampleLocations(input) {
    if (UnitOp2SampleLoc[input]) {
      const sampleLocations = UnitOp2SampleLoc[input].sampleLocations;
      const modifiedLocations = sampleLocations.map((location) =>
        location.replace(/ /g, "%20").replace(/#/g, "%23")
      );
      return modifiedLocations.join(",");
    } else if (ProcessLoc2UnitOp[input]) {
      const unitOps = ProcessLoc2UnitOp[input].unitOps;
      const sampleLocations = [];

      for (const unitOp of unitOps) {
        if (UnitOp2SampleLoc[unitOp]) {
          sampleLocations.push(...UnitOp2SampleLoc[unitOp].sampleLocations);
        }
      }

      const modifiedSamples = sampleLocations.map((location) =>
        location.replace(/ /g, "%20").replace(/#/g, "%23")
      );
      return modifiedSamples.join(",");
    } else {
      return "Invalid input - Unit Op or Process Area not found";
    }
  }

  // The code below is to filter the data based on Analyte or Sample Location. This makes it so the get is only of one type.

  const handleCellChange = (event, rowIndex, inputHeader) => {
    const updatedData = [...inputData];
    updatedData[rowIndex][inputHeader] = event.target.value;
    setInputData(updatedData);
  };

  const handleAddRow = async () => {
    var row4Add = [
      {
        SampleLocation: addSampleLocation,

        Analyte: addAnalyte,

        CollectDT: addCollectDT,

        SampleTestDT: addSampleLocation,

        Uom: "Units",

        ResultValue: addResultValue,

        UpperLimit: 0,

        LowerLimit: 100,
      },
    ];
    try {
      const response = await axios.put(`${apiUrl}/Samples`, row4Add, {
        timeout: 200000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          //Referer: "https://vesl.tech",
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    fetchData_SampleTbl();
  };

  const handleRowAdd = async () => {
    // Add logic to update Units of measure.
    setAddUOM("Units");
    var row2Add = [
      {
        SampleLocation: addSampleLocation,

        Analyte: addAnalyte,

        CollectDT: addCollectDT,

        SampleTestDT: addSampleTestDT,

        Uom: addUOM,

        ResultValue: addResultValue,

        UpperLimit: 100,

        LowerLimit: 0,
      },
    ];
    try {
      const response = await axios.put(`${apiUrl}/Samples`, row2Add, {
        timeout: 200000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          //Referer: "https://vesl.tech",
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    fetchData_SampleTbl();
  };

  const handleAddCommonRow = async () => {
    // Add logic to update Units of measure.
    setAddUOM("Units");
    var row2Add = [
      {
        SampleLocation: addCommonSampleLocation,

        Analyte: addCommonAnalyte,

        CollectDT: new Date(Date.now() - 6 * 60 * 60 * 1000),

        SampleTestDT: new Date(Date.now() - 6 * 60 * 60 * 1000),

        Uom: addUOM,

        ResultValue: addCommonResultValue,

        UpperLimit: 0,

        LowerLimit: 0,
      },
    ];
    try {
      const response = await axios.put(`${apiUrl}/Samples`, row2Add, {
        timeout: 200000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          //Referer: "https://vesl.tech",
        },
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    fetchData_SampleTbl();
  };

  const handleSaveRow = async (rowIndex) => {
    try {
      const updatedRow = inputData[rowIndex]; // Get the row you want to save

      // Send a PUT request to update the row in the backend
      await axios.put(`${apiUrl}/Samples/${updatedRow.id}`, updatedRow); // Replace with your backend API URL and ID

      // Update the state and exit edit mode
      setIsEditing(false);
      fetchData_SampleTbl();
      // Clear the newRow state for the next entry
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleDeleteRow = async (rowIndex) => {
    const shouldDelete = window.confirm("Are you sure you want to delete this row?");
    if (shouldDelete) {
      try {
        const rowToDelete = inputData[rowIndex];

        // Send a DELETE request to the backend to delete the row with the correct ID
        await axios.delete(`${apiUrl}/Samples/${rowToDelete.id}`);

        // If the delete request is successful, update the frontend state (inputData) accordingly
        const updatedData = [...inputData];
        updatedData.splice(rowIndex, 1);
        setInputData(updatedData);
      } catch (error) {
        console.error("Error deleting data:", error);
      }
    }
  };

  const handleEditRow = () => {
    setIsEditing(true);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const visibleRows = inputData.slice(startIndex, endIndex);

  return (
    <>
      <div>
        <TableContainer component={Paper} className="narrow-table">
          <Table aria-label="simple table">
            <TableBody>
              <TableRow>
                {inputHeader.map((header, cellIndex) => {
                  if (
                    header !== "id" &&
                    header !== "collectDT" &&
                    header !== "upperLimit" &&
                    header !== "lowerLimit"
                  ) {
                    // Exclude "id" header
                    return (
                      <TableCell align="center" key={cellIndex} className="bold-header">
                        {header}
                      </TableCell>
                    );
                  }
                  return null;
                })}
                {isEditing ? (
                  <TableCell align="center" className="bold-header">
                    Actions
                  </TableCell>
                ) : null}
              </TableRow>
            </TableBody>
            <TableBody>
              {visibleRows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {inputHeader.map((header, cellIndex) => {
                    if (
                      header !== "id" &&
                      header !== "collectDT" &&
                      header !== "upperLimit" &&
                      header !== "lowerLimit"
                    ) {
                      // Exclude "id" data
                      return (
                        <TableCell align="center" key={cellIndex}>
                          <input
                            type="text"
                            value={row[header]}
                            onChange={(event) =>
                              handleCellChange(event, rowIndex + rowsPerPage * page, header)
                            }
                            readOnly={!isEditing}
                          />
                        </TableCell>
                      );
                    }
                    return null;
                  })}
                  {isEditing ? (
                    <TableCell align="center">
                      <Button onClick={() => handleSaveRow(rowIndex + rowsPerPage * page)}>
                        Save
                      </Button>
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      <IconButton
                        onClick={() => handleDeleteRow(rowIndex + rowsPerPage * page)}
                        color="secondary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]} // You can customize the number of rows per page options
            component="div"
            count={inputData.length} // Total number of rows in your data
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 10));
              setPage(0); // Reset to the first page when rows per page changes
            }}
          />
        </TableContainer>
        <style>
          {`
    .half-width {
      width: 50%;
    }
  `}
        </style>
        <br></br>
        {!isEditing && (
          <Button onClick={handleEditRow} variant="contained" color="primary">
            Edit
          </Button>
        )}
      </div>
      <h1>Use the Following Boxes to Add Lab Data to Virutal Lab</h1>
      <h2>Common Sample Locations and Analytes</h2>
      <div className="selectors-box">
        <div className="dropdown-box">
          <label>Sample Location:</label>
          <select
            value={addCommonSampleLocation}
            onChange={(e) => setAddCommonSampleLocation(e.target.value)}
          >
            {sampleLocationCommonOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown-box">
          <label>Analyte:</label>
          <select value={addCommonAnalyte} onChange={(e) => setAddCommonAnalyte(e.target.value)}>
            {analyteCommonOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="input-box">
          <label>Value</label>
          <input
            type="text"
            value={addCommonResultValue}
            onChange={(e) => setAddCommonResultValue(e.target.value)}
          />
        </div>
      </div>
      <button className="submit-button" onClick={handleAddCommonRow}>
        Add Row to Table
      </button>
      <h2>Data Entry: All Sample Locations and Analytes</h2>
      <div className="selectors-box">
        <div className="dropdown-box">
          <label>Sample Location:</label>
          <select value={addSampleLocation} onChange={(e) => setAddSampleLocation(e.target.value)}>
            {sampleLocationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown-box">
          <label>Analyte:</label>
          <select value={addAnalyte} onChange={(e) => setAddAnalyte(e.target.value)}>
            {analyteOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="selectors-box">
        <div className="input-box">
          <label>Collection Time:</label>
          <input
            type="datetime-local"
            value={addCollectDT}
            onChange={(e) => setAddCollectDT(e.target.value)}
          />
        </div>
        <div className="input-box">
          <label>Sample Test Time</label>
          <input
            type="datetime-local"
            value={addSampleTestDT}
            onChange={(e) => setAddSampleTestDT(e.target.value)}
          />
        </div>
        <div className="input-box">
          <label>Value</label>
          <input
            type="text"
            value={addResultValue}
            onChange={(e) => setAddResultValue(e.target.value)}
          />
        </div>
      </div>
      <div className="selectors-box">
        <div className="input-box">
          <label>Upper Limit</label>
          <input
            type="text"
            value={addUpperLimit}
            onChange={(e) => setAddUpperLimit(e.target.value)}
          />
        </div>
        <div className="input-box">
          <label>Lower Limit</label>
          <input
            type="text"
            value={addLowerLimit}
            onChange={(e) => setAddLowerLimit(e.target.value)}
          />
        </div>
      </div>
      <button className="submit-button" onClick={handleRowAdd}>
        Add Row to Table
      </button>
    </>
  );
};

CreateTable.propTypes = {
  initialUnitOp: PropTypes.any,
  initialProcessLocation: PropTypes.any,
  initialSampleLocation: PropTypes.any,
  initialAnalyte: PropTypes.any,
  initialStartTime: PropTypes.any, // Changed to string since input type is datetime-local
  initialEndTime: PropTypes.any, // Changed to string since input type is datetime-local
  sourceData: PropTypes.array,
  countKey: PropTypes.number,
};

export default CreateTable;
