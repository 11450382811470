const filterData = (jsonData, analyte, sampleLocation, startTime, endTime, scaling) => {
  let result = [...jsonData];

  // Filter by analyte if it is provided
  if (analyte && analyte != "None") {
    result = result.filter((item) => item.analyte === analyte);
  }

  // Filter by sampleLocation if it is provided
  if (sampleLocation && sampleLocation != "None") {
    result = result.filter((item) => item.sampleLocation === sampleLocation);
  }

  if (startTime && endTime) {
    result = result.filter((item) => {
      const itemSampleTestDT = new Date(item.sampleTestDT);
      return itemSampleTestDT >= new Date(startTime) && itemSampleTestDT <= new Date(endTime);
    });
  }

  if (scaling) {
    result = result.filter((item) => item.resultValue > 0);
    result = result.filter((item) => item.resultValue < 100);
  }

  return result;
};

module.exports = filterData;
