import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Table, TableRow, TableCell, TableBody } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import axios from "axios";
import sampleLocationOptions from "../SampleLocations.js";
import sampleLocationCommonOptions from "./SampleLocationsCommonLosses.js";
import analyteCommonOptions from "../AnalytesCommonLosses.js";
//import LabAveragesPlot from "./LabSheetPlot.js";
import input from "assets/theme/components/form/input.js";
import "../Page.css";
import { TableHead } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const LabSheet = () => {
  const [inputData, setInputData] = useState([0]);
  const [inputHeader, setInputHeader] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const filterData = require("./filterJSONArray.js");
  const filterData2 = require("../filterJSON.js");
  const [commonAnalytes, setCommonAnalytes] = useState(analyteCommonOptions);
  const [commonSampleLocation, setCommonSampleLocation] = useState(sampleLocationCommonOptions);

  useEffect(() => {
    fetchData_SampleTbl();
  }, []);

  function calculateAverage(data, analyte, sampleLocation, timeInterval) {
    // Filter data based on analyte and sample location
    const filteredData = data.filter(
      (item) => item.analyte === analyte && item.sampleLocation === sampleLocation
    );

    // Calculate the average based on the time interval
    const now = new Date();
    const intervalStart = new Date(now - timeInterval);
    const relevantData = filteredData.filter(
      (item) => new Date(item.sampleTestDT) >= intervalStart
    );

    if (relevantData.length === 0) {
      return null; // Return 0 if no data is available for the given time interval
    }

    const resultValues = relevantData.map((item) => item.resultValue);

    const sum = resultValues.reduce((accumulator, currentValue) => accumulator + currentValue);
    const average = sum / resultValues.length;
    return average;
  }
  const timeNow = new Date();
  timeNow.setMinutes(0);
  timeNow.setSeconds(0);

  const fetchData_SampleTbl = async () => {
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_URL}/Samples`, {
        timeout: 200000,
        headers: {
          crossDomain: true,
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      });

      let dataArr = response.data;
      dataArr = filterData(dataArr, commonAnalytes, commonSampleLocation);
      dataArr.reverse();
      setInputData(dataArr);
      setInputHeader(Object.keys(dataArr[0]));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function calculateHourlyAverage(data, analyte, sampleLocation, timeInterval) {
    if (sampleLocation === "None") {
      return null;
    }
    const now = new Date();
    const intervalStart = new Date(now - timeInterval * 3600000); // Convert hours to milliseconds
    const intervalEnd = new Date(intervalStart);
    intervalEnd.setHours(intervalEnd.getHours() + 1); // Assuming hourly intervals

    const filteredData = filterData2(
      data,
      analyte,
      sampleLocation,
      intervalStart,
      intervalEnd,
      false
    );

    if (filteredData.length === 0) {
      return null; // Return null if no data is available for the given time interval
    }

    const resultValues = filteredData.map((item) => item.resultValue);

    const sum = resultValues.reduce((accumulator, currentValue) => accumulator + currentValue);
    const average = sum / resultValues.length;
    return average;
  }

  // Define an array of hours for which you want to calculate averages
  const hoursToCalculate = Array.from({ length: 24 }, (_, i) => i);

  return (
    <>
      <h1>Lab Sheets</h1>
      <h2>Tracking by Hour</h2>
      <div>
        <TableContainer component={Paper} className="hourlylosses-table">
          <TableRow className="sticky-row">
            <TableCell>
              <strong>Sample Location</strong>
            </TableCell>
            <TableCell>
              <strong>Analyte</strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 23 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 23 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 22 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 22 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 21 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 21 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 20 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 20 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 19 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 19 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 18 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 18 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 17 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 17 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 16 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 16 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 15 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 15 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 14 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 14 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 13 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 13 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 12 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 12 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 11 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 11 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 10 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 10 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 9 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 9 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 8 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 8 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 7 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 7 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 6 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 6 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 5 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 5 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 4 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 4 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 3 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 3 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 2 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 2 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {new Date(timeNow - 3600 * 1000).getHours()}:
                {new Date(timeNow - 3600 * 1000).getMinutes() + "0"}
              </strong>
            </TableCell>
            <TableCell>
              <strong>
                {timeNow.getHours()}:{timeNow.getMinutes() + "0"}
              </strong>
            </TableCell>
          </TableRow>
          <Table aria-label="Hour by Hour Table">
            <TableBody>
              {commonSampleLocation.map((location) =>
                commonAnalytes.map((analyte) => {
                  if (!(location === "Mol Produced" && analyte === "% Sugar")) {
                    const hr23 = calculateHourlyAverage(inputData, analyte, location, 23);
                    const hr22 = calculateHourlyAverage(inputData, analyte, location, 22);
                    const hr21 = calculateHourlyAverage(inputData, analyte, location, 21);
                    const hr20 = calculateHourlyAverage(inputData, analyte, location, 20);
                    const hr19 = calculateHourlyAverage(inputData, analyte, location, 19);
                    const hr18 = calculateHourlyAverage(inputData, analyte, location, 18);
                    const hr17 = calculateHourlyAverage(inputData, analyte, location, 17);
                    const hr16 = calculateHourlyAverage(inputData, analyte, location, 16);
                    const hr15 = calculateHourlyAverage(inputData, analyte, location, 15);
                    const hr14 = calculateHourlyAverage(inputData, analyte, location, 14);
                    const hr13 = calculateHourlyAverage(inputData, analyte, location, 13);
                    const hr12 = calculateHourlyAverage(inputData, analyte, location, 12);
                    const hr11 = calculateHourlyAverage(inputData, analyte, location, 11);
                    const hr10 = calculateHourlyAverage(inputData, analyte, location, 10);
                    const hr9 = calculateHourlyAverage(inputData, analyte, location, 9);
                    const hr8 = calculateHourlyAverage(inputData, analyte, location, 8);
                    const hr7 = calculateHourlyAverage(inputData, analyte, location, 7);
                    const hr6 = calculateHourlyAverage(inputData, analyte, location, 6);
                    const hr5 = calculateHourlyAverage(inputData, analyte, location, 5);
                    const hr4 = calculateHourlyAverage(inputData, analyte, location, 4);
                    const hr3 = calculateHourlyAverage(inputData, analyte, location, 3);
                    const hr2 = calculateHourlyAverage(inputData, analyte, location, 2);
                    const hr1 = calculateHourlyAverage(inputData, analyte, location, 1);
                    const hr0 = calculateHourlyAverage(inputData, analyte, location, 0);

                    return hr23 != null ||
                      hr22 != null ||
                      hr21 != null ||
                      hr20 != null ||
                      hr19 != null ||
                      hr18 != null ||
                      hr17 != null ||
                      hr16 != null ||
                      hr15 != null ||
                      hr14 != null ||
                      hr13 != null ||
                      hr12 != null ||
                      hr11 != null ||
                      hr10 != null ||
                      hr9 != null ||
                      hr8 != null ||
                      hr7 != null ||
                      hr6 != null ||
                      hr5 != null ||
                      hr4 != null ||
                      hr3 != null ||
                      hr2 != null ||
                      hr1 != null ||
                      hr0 != null ? (
                      <TableRow key={`${location}-${analyte}`}>
                        <TableCell>{location}</TableCell>
                        <TableCell>{analyte}</TableCell>
                        <TableCell>{hr23 !== null ? hr23.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr22 !== null ? hr22.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr21 !== null ? hr21.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr20 !== null ? hr20.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr19 !== null ? hr19.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr18 !== null ? hr18.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr17 !== null ? hr17.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr16 !== null ? hr16.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr15 !== null ? hr15.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr14 !== null ? hr14.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr13 !== null ? hr13.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr12 !== null ? hr12.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr11 !== null ? hr11.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr10 !== null ? hr10.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr9 !== null ? hr9.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr8 !== null ? hr8.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr7 !== null ? hr7.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr6 !== null ? hr6.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr5 !== null ? hr5.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr4 !== null ? hr4.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr3 !== null ? hr3.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr2 !== null ? hr2.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr1 !== null ? hr1.toFixed(2) : ""}</TableCell>
                        <TableCell>{hr0 !== null ? hr0.toFixed(2) : ""}</TableCell>
                      </TableRow>
                    ) : null;
                  }
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <h2 className="averages">Average Values</h2>
      <p className="averages">
        Note: If this table is empty, no values have been entered in last 24 hours
      </p>
      <div>
        <TableContainer component={Paper} className="lab-sheet-table">
          <Table aria-label="average table">
            <TableBody>
              <TableRow>
                <TableCell>Sample Location</TableCell>
                <TableCell>Analyte</TableCell>
                <TableCell>Average Over 3 Hours</TableCell>
                <TableCell>Average Over 24 Hours</TableCell>
                <TableCell>Average Over Last 7 Days</TableCell>
                <TableCell>Average Over All Time</TableCell>
              </TableRow>
              {commonSampleLocation.map((location) =>
                commonAnalytes.map((analyte) => {
                  if (!(location === "Mol Produced" && analyte === "% Sugar")) {
                    const avg3Hours = calculateAverage(
                      inputData,
                      analyte,
                      location,
                      3 * 60 * 60 * 1000
                    );
                    const avg24Hours = calculateAverage(
                      inputData,
                      analyte,
                      location,
                      24 * 60 * 60 * 1000
                    );
                    const avg1Week = calculateAverage(
                      inputData,
                      analyte,
                      location,
                      7 * 24 * 60 * 60 * 1000
                    );
                    const avgAllTime = calculateAverage(
                      inputData,
                      analyte,
                      location,
                      30 * 365 * 24 * 60 * 60 * 1000
                    );

                    return avg24Hours !== null && avg1Week !== null && avgAllTime !== null ? (
                      <TableRow key={`${location}-${analyte}`}>
                        <TableCell>{location}</TableCell>
                        <TableCell>{analyte}</TableCell>
                        <TableCell>{avg3Hours !== null ? avg3Hours.toFixed(2) : ""}</TableCell>
                        <TableCell>{avg24Hours !== null ? avg24Hours.toFixed(2) : ""}</TableCell>
                        <TableCell>{avg1Week !== null ? avg1Week.toFixed(2) : ""}</TableCell>
                        <TableCell>{avgAllTime !== null ? avgAllTime.toFixed(2) : ""}</TableCell>
                      </TableRow>
                    ) : null;
                  }
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

LabSheet.propTypes = {
  sheetType: PropTypes.string.isRequired,
};

export default LabSheet;
