import React from "react";
import { useMsal } from "@azure/msal-react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { loginRequest } from "./authConfig";
import "../../layouts/dashboards/LabDash/Page.css";

/**
 * Renders a sign out button
 */
export const SignOutButton = (logoutMethod) => {
  let _postLogoutRedirectUri = "/signout-oidc";
  let _mainWindowRedirectUri = "/signout-oidc";

  const { instance } = useMsal();
  const handleLogout = (logoutMethod) => {
    if (logoutMethod === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: _postLogoutRedirectUri,
        mainWindowRedirectUri: _mainWindowRedirectUri,
      });
    } else if (logoutMethod === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };

  return (
    <>
      <button className="sign-button" onClick={() => handleLogout("redirect")}>
        Sign Out
      </button>
    </>
  );
};
