const SampleLocations = [
  "Cossette",
  "Pr Pulp Water",
  "Diff Supply Water",
  "Raw Jce Exit",
  "PreLimer C#1",
  "PreLimer C#2",
  "PreLimer C#3",
  "PreLimer C#4",
  "PreLimer C#5",
  "PreLimer C#6",
  "Milk of Lime",
  "MainLimer",
  "1st Carb",
  "2nd Carb",
  "Thin Juice",
  "Down Thin Juice",
  "Evap Thick Juice",
  "Clarifier",
  "Lime Flume",
  "Main Sewer",
  "Seal Tank",
];

export default SampleLocations;
