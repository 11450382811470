import React from "react";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom/client"; // Added for MSAL
import { PublicClientApplication, EventType } from "@azure/msal-browser"; // Added for MSAL

import { BrowserRouter } from "react-router-dom";
import { msalConfig } from "./components/MSAL/authConfig.js";

import App from "./App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

// MSAL SETUP START ////////////////////////////////////////////////
/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md

AZURE AD B2C APP REGISTRATION  
* Display name: vesl-virtual-factory-dashApi
 * Application (client) ID: fd3e1be5-a06b-44b3-b9a3-26fc3ac70e3a
* Object ID: 5574405c-b9de-496a-ba96-06a42d1842e5
* Directory (tenant) ID: 9c544985-ab28-4a09-b988-3a9f24f67d0b
 */

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (
    (event.eventType === EventType.LOGIN_SUCCESS ||
      event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
      event.eventType === EventType.SSO_SILENT_SUCCESS) &&
    event.payload.account
  ) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});
// MSAL SETUP END ////////////////////////////////////////////////
/*
1. defines the first layer in defining thre react app (aka the base dom
2. you can? add multiple things to the dom?
3. browser router manges address for all the sub doms(aka virtual doms)
*/
// Just Guessed that I should use root, don't know what this does.
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MaterialUIControllerProvider>
        {/* Call App.js*/}
        <App instance={msalInstance} />
        {/*<App />*/}
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </React.StrictMode>
);
