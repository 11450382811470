import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import unitOpOptions from "./UnitOps.js";
import sampleLocationOptions from "./SampleLocations.js";
import analyteOptions from "./Analytes.js";
import ProcessAreaArr from "./ProcessAreaArr.js";
import axios from "axios";
import ProcessLoc2UnitOp from "./ProcessLoc2UnitOp.js";
import UnitOp2SampleLoc from "./UnitOp2SampleLoc.js";

import { render } from "react-dom";
import "./Page.css";

function Plot({
  initialUnitOp,
  initialProcessLocation,
  initialSampleLocation,
  initialAnalyte,
  initialStartTime,
  initialEndTime,
  countKey,
}) {
  const scatterRef = useRef(null);
  const legendRef = useRef(null); // Ref for the legend
  const [apiData, setApiData] = useState(null);
  const [legendVisible, setLegendVisible] = useState(false);
  const [seeAnalyte, setSeeAnalyte] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  const [scaling, setScaling] = useState(true);
  const filterData = require("./filterJSON");
  var sampleLocationString = getSampleLocations("all");
  var filteredOptions = sampleLocationString;

  var colorScale = d3.scaleOrdinal().domain(sampleLocationOptions).range(d3.schemeCategory10);

  const fetchData = () => {
    if (initialUnitOp !== null && initialUnitOp !== "None") {
      sampleLocationString = getSampleLocations(initialUnitOp);
    } else if (initialProcessLocation !== null && initialProcessLocation !== "None") {
      sampleLocationString = getSampleLocations(initialProcessLocation);
    }
    return d3.json(
      `${process.env.REACT_APP_API_URL}/Samples/ByLocationArr?sampleLocations=${sampleLocationString}`
    );
  };
  const updatePlot = async (currentScaling) => {
    try {
      var newData = await fetchData();
      newData = filterData(
        newData,
        initialAnalyte,
        initialSampleLocation,
        initialStartTime,
        initialEndTime,
        currentScaling // Use the captured 'currentScaling' value
      );
      newData = newData.filter((d) => d.sampleTestDT !== null);
      setApiData(newData);
      renderLegend();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData_SampleTbl();
  }, []);

  useEffect(() => {
    renderLegend();
  }, [legendVisible]);

  useEffect(() => {
    fetchData_SampleTbl();
    let pageUpdateTimer = 1000; // Update every minute
    const intervalId = setInterval(() => {
      updatePlot(scaling); // Pass the current value of 'scaling'
    }, pageUpdateTimer);
    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [countKey, scaling, , initialUnitOp, initialProcessLocation, seeAnalyte]);

  // Synchronous Call
  // Synchronous Call
  const fetchData_SampleTbl = async () => {
    if (initialUnitOp !== null && initialUnitOp !== "None") {
      sampleLocationString = getSampleLocations(initialUnitOp);
    } else if (initialProcessLocation !== null && initialProcessLocation !== "None") {
      sampleLocationString = getSampleLocations(initialProcessLocation);
    }
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}/Samples/ByLocationArr?sampleLocations=${sampleLocationString}`,
        {
          timeout: 20000,
          headers: {
            crossDomain: true,
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            //Referer:"https://vesl.tech",
          },
        }
      );

      let dataArr = response.data;
      dataArr = filterData(
        dataArr,
        initialAnalyte,
        initialSampleLocation,
        initialStartTime,
        initialEndTime,
        scaling
      );
      newData = newData.filter((d) => d.sampleTestDT !== null);
      setApiData(dataArr);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function getSampleLocations(input) {
    if (UnitOp2SampleLoc[input]) {
      const sampleLocations = UnitOp2SampleLoc[input].sampleLocations;
      const modifiedLocations = sampleLocations.map((location) =>
        location.replace(/ /g, "%20").replace(/#/g, "%23")
      );
      return modifiedLocations.join(",");
    } else if (ProcessLoc2UnitOp[input]) {
      const unitOps = ProcessLoc2UnitOp[input].unitOps;
      const sampleLocations = [];

      for (const unitOp of unitOps) {
        if (UnitOp2SampleLoc[unitOp]) {
          sampleLocations.push(...UnitOp2SampleLoc[unitOp].sampleLocations);
        }
      }

      const modifiedSamples = sampleLocations.map((location) =>
        location.replace(/ /g, "%20").replace(/#/g, "%23")
      );
      return modifiedSamples.join(",");
    } else {
      return "Invalid input - Unit Op or Process Area not found";
    }
  }

  const toggleLegend = () => {
    setLegendVisible(!legendVisible);
  };

  const toggleScaling = () => {
    setScaling(!scaling);
    updatePlot();
  };

  const toggleAnalyte = () => {
    setSeeAnalyte(!seeAnalyte);
    if (seeAnalyte) {
      colorScale = d3.scaleOrdinal().domain(analyteOptions).range(d3.schemeCategory10);
      updatePlot();
    } else {
      colorScale = d3.scaleOrdinal().domain(sampleLocationOptions).range(d3.schemeCategory10);
      updatePlot();
    }
  };

  useEffect(() => {
    if (!apiData) return; // Wait until data is available

    var filteredData = apiData.filter((d) => d.sampleTestDT !== null);

    // Use d3.timeParse to parse date-time strings
    const parseTime = d3.timeParse("%Y-%m-%dT%H:%M:%S");

    filteredData.forEach((d) => {
      d.sampleTestDT = parseTime(d.sampleTestDT); // Parse the date-time values
    });

    renderScatterPlot(filteredData);
  }, [apiData, initialUnitOp, initialProcessLocation, initialSampleLocation, initialAnalyte]);

  const renderScatterPlot = () => {
    // Set up the dimensions and margins for the scatter plot
    const margin = { top: 50, right: 70, bottom: 90, left: 100 };
    const width = 960 - margin.left - margin.right;
    const height = 600 - margin.top - margin.bottom;

    // Clear the SVG container
    d3.select(scatterRef.current).selectAll("*").remove();

    // Create scales for x and y axes
    const xScale = d3.scaleTime().range([0, width]);
    const yScale = d3.scaleLinear().range([height, 0]);

    // Find the minimum and maximum date values
    const minDate = d3.min(apiData, (d) => d.sampleTestDT);
    const maxDate = d3.max(apiData, (d) => d.sampleTestDT);

    // Calculate a buffer value in milliseconds (e.g., 1 hour)
    const bufferMs = 60 * 60 * 1000 * 24.5; // Adjust this value as needed

    // Adjust the domain by subtracting the buffer from the minimum date
    xScale.domain([minDate - bufferMs, maxDate]);

    // Find the maximum data point value
    const maxDataValue = d3.max(apiData, (d) => d.resultValue);

    // Set a buffer between the minimum y value and the y-axis
    const yBuffer = 10; // You can adjust this value as needed
    yScale.domain([0, maxDataValue + yBuffer]);

    // Create an SVG element
    const svgScatter = d3
      .select(scatterRef.current)
      .attr("class", "chart-container")
      .attr("width", width + margin.left + margin.right + 50)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    // Create circles for the scatter plot
    svgScatter
      .selectAll("circle")
      .data(apiData)
      .enter()
      .append("circle")
      .attr("cx", (d) => (d.sampleTestDT ? xScale(d.sampleTestDT) : null))
      .attr("cy", (d) => yScale(d.resultValue))
      .attr("r", (d) => (d.sampleTestDT !== null ? 5 : 0))
      .style("fill", (d) => (seeAnalyte ? colorScale(d.analyte) : colorScale(d.sampleLocation)))
      .on("mouseover", (event, d) => {
        // Show tooltip on mouseover
        const tooltip = svgScatter.append("g").attr("class", "tooltip").style("display", "none");

        tooltip
          .append("rect")
          .attr("width", 250) // Adjusted the width to fit all data
          .attr("height", 100) // Adjusted the height
          .attr("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", 1)
          .attr("rx", 5) // Rounded corners
          .attr("ry", 5); // Rounded corners

        // Add text to the tooltip with data values
        const dateFormat = d3.timeFormat("%b %d, %H:%M");
        tooltip
          .append("text")
          .attr("x", 10)
          .attr("y", 20)
          .text(`Sample Location: ${d.sampleLocation}`);

        tooltip.append("text").attr("x", 10).attr("y", 40).text(`analyte ${d.analyte}`);

        tooltip.append("text").attr("x", 10).attr("y", 60).text(`Value: ${d.resultValue}`);

        tooltip
          .append("text")
          .attr("x", 10)
          .attr("y", 80)
          .text(`Sample Time: ${dateFormat(d.sampleTestDT)}`);
        tooltip.attr(
          "transform",
          `translate(${xScale(d.sampleTestDT) - 95},${yScale(d.resultValue) - 90})`
        );

        tooltip.style("display", "block");
      })
      .on("mouseout", () => {
        // Hide tooltip on mouseout
        svgScatter.selectAll(".tooltip").style("display", "none");
      });
    // Create x and y axes
    svgScatter
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3.axisBottom(xScale).ticks(5).tickSizeOuter(0).tickFormat(d3.timeFormat("%m-%d %H:%M"))
      );

    const yAxis = d3.axisLeft(yScale).ticks(10);
    svgScatter
      .append("g")
      .attr("class", "y-axis")
      .call(d3.axisRight(yScale).ticks(10).tickSize(15))
      .selectAll("text")
      .attr("transform", "rotate(-45)")
      .style("text-anchor", "end");

    // Labels and styling
    svgScatter
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - margin.bottom / 2 + 95)
      .attr("text-anchor", "middle")
      .text("Date/Time");

    svgScatter
      .append("text")
      .attr("x", height / 2 - 380)
      .attr("y", margin.left / 2 + 200)
      .attr("text-anchor", "middle")
      .text("Value");
  };

  //BROKEN STUFF WHERE LEGEND RENDERS AND DROPS WHOLE PAGE
  // Create a legend
  const renderLegend = () => {
    d3.select(legendRef.current).selectAll("*").remove();
    // Define legend dimensions and margins
    if (!seeAnalyte) {
      const filteredSampleLocationOptions = sampleLocationOptions.filter((sampleLocation) => {
        if (!apiData) {
          return true; // Return true to include all options when there is no data
        } else {
          return apiData.some((data) => data.sampleLocation === sampleLocation);
        }
      });
      filteredOptions = filteredSampleLocationOptions;
    } else {
      const filteredAnalyteOptions = analyteOptions.filter((analyte) => {
        if (!apiData) {
          return true; // Return true to include all options when there is no data
        } else {
          return apiData.some((data) => data.analyte === analyte);
        }
      });
      filteredOptions = filteredAnalyteOptions;
    }
    const legendWidth = 200;
    const legendHeight = filteredOptions.length * 30;
    const legendMargin = { top: 20, right: 20, bottom: 20, left: 20 };
    // Create an SVG element for the legend
    const svgLegend = d3
      .select(legendRef.current)
      .append("svg")
      .attr("width", legendWidth + legendMargin.left + legendMargin.right)
      .attr("height", legendHeight + legendMargin.top + legendMargin.bottom)
      .append("g")
      .attr("transform", `translate(${legendMargin.left},${legendMargin.top})`);
    // Create a legend box
    svgLegend
      .append("defs")
      .append("filter")
      .attr("id", "drop-shadow")
      .attr("x", "-20%")
      .attr("y", "-20%")
      .attr("width", "140%")
      .attr("height", "140%")
      .append("feDropShadow")
      .attr("dx", "2")
      .attr("dy", "2")
      .attr("stdDeviation", "2");
    // Filter sampleLocationOptions based on available data in apiData
    // If there are no filtered options, add a default entry
    if (filteredOptions.length === 0) {
      filteredOptions.push("No Data");
    }
    // Create legend entries
    const legendEntries = svgLegend
      .selectAll(".legend-entry")
      .data(filteredOptions)
      .enter()
      .append("g")
      .attr("class", "legend-entry")
      .attr("transform", (d, i) => `translate(0, ${i * 30})`);
    // Add color squares
    legendEntries
      .append("rect")
      .attr("width", 20)
      .attr("height", 20)
      .style("fill", (d) => (d === "No Data" ? "gray" : colorScale(d))); // Use gray for the default entry
    // Add legend text
    legendEntries
      .append("text")
      .attr("x", 30)
      .attr("y", 15)
      .text((d) => d);
  };
  return (
    <div>
      <svg ref={scatterRef}></svg>
      <br />
      <button className="plot-button" onClick={toggleLegend}>
        Toggle Legend
      </button>
      <button className="plot-button" onClick={toggleAnalyte}>
        {seeAnalyte ? "Toggle By Sample Location" : "Toggle by Analyte"}
      </button>
      <button className="plot-button" onClick={toggleScaling}>
        Toggle Scaling
      </button>
      {legendVisible ? <div ref={legendRef} className="legend"></div> : null}
    </div>
  );
}
Plot.propTypes = {
  initialUnitOp: PropTypes.any,
  initialProcessLocation: PropTypes.any,
  initialSampleLocation: PropTypes.any,
  initialAnalyte: PropTypes.any,
  initialStartTime: PropTypes.any, // Changed to string since input type is datetime-local
  initialEndTime: PropTypes.any, // Changed to string since input type is datetime-local
  countKey: PropTypes.number,
};

export default Plot;
