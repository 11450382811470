const UnitOps = [
  "None",
  "Slicers",
  "Diffuser",
  "Carb Gassing",
  "Carb liming",
  "EnviroClear",
  "Durco Filters",
  "2nd Carb Filters",
  "Clarifier",
  "Pulp Presses",
  "Lime Kiln",
  "Evaporators",
  "Melters",
  "Std Liquor Filters",
  "Concentrator",
  "Thick Juice Stor",
  "White Centrifuge",
  "Prod Scale",
  "Crystlalizer",
  "Cooling Tower",
  "White Raw Pans",
  "High Raw Pans",
  "Low Raw Pans",
  "Conditioning bits",
  "Silos",
];
export default UnitOps;
