import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VeslLogo from "../../../assets/images/vesl_logo_black_font_transparent.png";
import SignInSignOutButton from "components/MSAL/SignInSignOutButton";

function LabDashNavBar() {
  // State for handling the user menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  // Function to open the user menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the user menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToDashboard = () => {
    // Use the <Navigate /> component to navigate to the desired route
    navigate("/vfactory/dash");
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "white" }}>
      <Toolbar>
        {/* Left side - Brand/Logo */}
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <img src={VeslLogo} alt="Vesl Logo" style={{ height: "30px" }} />
        </Typography>

        {/* Right side - Dropdowns and Login Button */}
        <div>
          {/* Dropdown for user menu */}
          {/* <Button
            onClick={navigateToDashboard}
            style={{ border: "1px solid #000", padding: "8px 16px", borderRadius: "4px" }}
          >
            Lab Dashboard
          </Button> */}
          {/* Login Button */}
          <SignInSignOutButton />
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default LabDashNavBar;
