const filterData = (jsonData, analytes, sampleLocations) => {
  let result = [...jsonData];

  // Filter by analyte if the array is provided
  if (Array.isArray(analytes) && analytes.length > 0) {
    result = result.filter((item) => analytes.includes(item.analyte));
  }

  // Filter by sampleLocation if the array is provided
  if (Array.isArray(sampleLocations) && sampleLocations.length > 0) {
    result = result.filter((item) => sampleLocations.includes(item.sampleLocation));
  }

  return result;
};

module.exports = filterData;
