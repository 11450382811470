import React, { useState, useEffect, setState } from "react";
import PropTypes from "prop-types";
import unitOpOptions from "./UnitOps.js";
import sampleLocationOptions from "./SampleLocationsNoneTop.js";
import analyteOptions from "./AnalytesNoneTop.js";
import ProcessAreaArr from "./ProcessAreaArr.js";
import CreateTable from "./CreateTable.js";
import CreatePlot from "./CreatePlot.js";
import "./Page.css";
import { filter } from "vesl-landing-page/fontawesome-free-5.12.0-web-1/js/v4-shims.js";
import LabDashNavBar from "./LabDashNavBar.js";

const SelectorsTablePlot = ({
  initialUnitOp,
  initialProcessLocation,
  initialSampleLocation,
  initialAnalyte,
  initialStartTime,
  initialEndTime,
}) => {
  const [processLocation, setProcessLocation] = useState(initialProcessLocation || "");
  const [unitOp, setUnitOp] = useState(initialUnitOp || "");
  const [sampleLocation, setSampleLocation] = useState(initialSampleLocation || "");
  const [analyte, setAnalyte] = useState(initialAnalyte || "");
  const [startTime, setStartTime] = useState(initialStartTime || "");
  const [endTime, setEndTime] = useState(initialEndTime || "");
  const [count, setCount] = useState(0);
  const [filterValues, setFilterValues] = useState({
    analyte: "None",
    sampleLocation: "None",
    endTime: null, //new Date(), // Set the end time to the current time
    startTime: null, //new Date(Date.now() - 24 * 60 * 60 * 1000), // Set the start time to 3 hours ago
  });

  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };
  const handleSelections = () => {
    setFilterValues({
      analyte: analyte,
      sampleLocation: sampleLocation,
      endTime: endTime,
      startTime: startTime,
    });
    setCount((k) => k + 1);
  };

  useEffect(() => {}, [filterValues]);

  return (
    <>
      <div style={{ width: "1100px" }}>
        <LabDashNavBar />
      </div>
      <h1>Process Loc: {initialProcessLocation ? initialProcessLocation : "All"}</h1>
      <h1>Unit Op: {initialUnitOp ? initialUnitOp : "All"}</h1>
      <div>
        <h2 className="filter-title">FILTER DATA</h2>
        <h2>
          Select the sample location, analyte and <br></br> datetime to filter the virtual lab
          database
        </h2>
      </div>
      <div className="selectors-box">
        <div className="dropdown-box">
          <label>Sample Location:</label>
          <select value={sampleLocation} onChange={(e) => setSampleLocation(e.target.value)}>
            {sampleLocationOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="dropdown-box">
          <label>Analyte:</label>
          <select value={analyte} onChange={(e) => setAnalyte(e.target.value)}>
            {analyteOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="selectors-box">
        <div className="input-box">
          <label>Start Date Time:</label>
          <input type="datetime-local" value={startTime} onChange={handleStartTimeChange} />
        </div>
        <div className="input-box">
          <label>End Date Time:</label>
          <input type="datetime-local" value={endTime} onChange={handleEndTimeChange} />
        </div>
      </div>
      <button className="submit-button" onClick={handleSelections}>
        Apply Filters
      </button>
      {/* Dropdowns at Top*/}
      <br />
      <CreatePlot
        initialProcessLocation={initialProcessLocation}
        initialUnitOp={initialUnitOp}
        initialAnalyte={filterValues.analyte}
        initialSampleLocation={filterValues.sampleLocation}
        initialStartTime={filterValues.startTime}
        initialEndTime={filterValues.endTime}
        countKey={count}
      />
      <br />
      <CreateTable
        initialProcessLocation={initialProcessLocation}
        initialUnitOp={initialUnitOp}
        initialAnalyte={filterValues.analyte}
        initialSampleLocation={filterValues.sampleLocation}
        initialStartTime={filterValues.startTime}
        initialEndTime={filterValues.endTime}
        countKey={count}
      />
    </>
  );
};

// Constructor for The Dropdowns
SelectorsTablePlot.propTypes = {
  initialUnitOp: PropTypes.any,
  initialProcessLocation: PropTypes.any,
  initialSampleLocation: PropTypes.any,
  initialAnalyte: PropTypes.any,
  initialStartTime: PropTypes.any, // Changed to string since input type is datetime-local
  initialEndTime: PropTypes.any, // Changed to string since input type is datetime-local
};

export default SelectorsTablePlot;
