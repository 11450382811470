import React from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, IconButton, Menu, MenuItem } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VeslLogo from "../../assets/images/vesl_logo_black_font_transparent.png";
import SignInSignOutButton from "components/MSAL/SignInSignOutButton";
import "../../layouts/dashboards/LabDash/Page.css";

function LandingPageNavBar() {
  // State for handling the user menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();

  // Function to open the user menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to close the user menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const navigateToDashboard = () => {
    // Use the <Navigate /> component to navigate to the desired route
    navigate("/vfactory/dash");
  };

  const handleSignUpClick = () => {
    // Replace 'https://example.com' with the URL you want to navigate to
    window.location.href = "https://forms.office.com/r/NB8pUYy33r";
  };

  const handleForgotPWClick = () => {
    // Replace 'https://example.com' with the URL you want to navigate to
    window.location.href = "https://forms.office.com/r/tHtzibMdzL";
  };

  return (
    <AppBar position="static" style={{ backgroundColor: "white" }}>
      <Toolbar>
        {/* Left side - Brand/Logo */}
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          <img src={VeslLogo} alt="Vesl Logo" style={{ height: "30px" }} />
        </Typography>

        {/* Right side - Dropdowns and Login Button */}
        <div>
          <div className="selectors-box">
            <button className="sign-button" onClick={handleSignUpClick}>
              {" "}
              Sign Up{" "}
            </button>
            <div style={{ margin: "5px" }}></div>
            <SignInSignOutButton />
            <div style={{ margin: "5px" }}></div>
            <button className="sign-button" onClick={handleForgotPWClick}>
              {" "}
              Forgot Password{" "}
            </button>
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default LandingPageNavBar;
