import React from "react";

class FeedbackButton extends React.Component {
  openForm() {
    // Replace the URL below with your Microsoft Form URL
    const formUrl =
      "https://forms.office.com/Pages/ResponsePage.aspx?id=ZjhWcdHzIU2QdQic1OYbO26YG1oRVGVBt1rGenb8CUVUOTdZMk1aUU9QR1ZNRzU3V0RRWVgzUFdLMi4u";

    // Open the Microsoft Form in a new tab/window
    window.open(formUrl, "_blank");
  }

  render() {
    const buttonStyle = {
      backgroundColor: "#0044cc", // Darker blue color
      color: "white",
      padding: "12px 24px", // Increase padding to make it larger
      borderRadius: "4px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Drop shadow
      border: "none",
      cursor: "pointer",
      fontSize: "16px", // Font size
      transition: "background-color 0.3s, transform 0.3s",
    };

    return (
      <button onClick={this.openForm} style={buttonStyle}>
        Feedback Form For VESL! We want to hear from you!
      </button>
    );
  }
}

export default FeedbackButton;
