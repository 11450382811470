import { useState, useEffect, useMemo, useContext } from "react";
// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 PRO React components
import MDBox from "./components/MDBox";
import Sidenav from "./examples/Sidenav";
import Configurator from "./examples/Configurator";
import Footer from "./examples/Footer";
// Material Dashboard 2 PRO React themes
import theme from "./assets/theme";
import themeRTL from "./assets/theme/theme-rtl";
// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "./assets/theme-dark";
import themeDarkRTL from "./assets/theme-dark/theme-rtl";
// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
// Material Dashboard 2 PRO React routes
import routes from "routes";
// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
// Images
// VESL WHITE LOGO
// VESL DARK LOGO
import brandWhite from "./assets/images/vesl-logo-colored.svg";
import brandDark from "./assets/images/vesl_logo_black_font_transparent.png";

import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import LandingPage from "pages/LandingPage";

// Landing Page  is What builds the Whole Dash
//  Main component should only be shown once the user is authenticated
export const BaseDash = (props) => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Map All of the Routes fr
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return (
    <ThemeProvider
      theme={
        direction === "rtl" ? (darkMode ? themeDarkRTL : themeRTL) : darkMode ? themeDark : theme
      }
    >
      <CssBaseline />
      <div style={{ display: "flex", maxWidth: "100%" }}>
        {" "}
        {/* Set a maximum width */}
        {layout === "dashboard" && (
          <div style={{ width: "250px", flexShrink: 0 }}>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="VESL Virtual Factory"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          </div>
        )}
        <div style={{ flex: 1, paddingLeft: layout === "dashboard" ? "250px" : "0" }}>
          {layout === "dashboard" && <Configurator />}
          <Routes>
            {/*w*/}
            {getRoutes(routes)}

            <Route path="*" element={<Navigate to="/vfactory/dash" />} />
          </Routes>
          <MDBox>
            <Footer company="VESL" />
          </MDBox>
        </div>
      </div>
      {configsButton}
    </ThemeProvider>
  );
};

/*
PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
*/
