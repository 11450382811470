/**
VESL /** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
import AbstractPage from "layouts/dashboards/LabDash/AbstractPage";
import AbstractLabSheet from "./layouts/dashboards/LabDash/LabSheets/AbstractLabSheet";
import AbstractLabSheetBeetEnd from "./layouts/dashboards/LabDash/LabSheets/AbstractLabSheetBeetEnd";
import AbstractLabSheetSugarEnd from "./layouts/dashboards/LabDash/LabSheets/AbstractLabSheetSugarEnd";
import AbstractLabSheetLosses from "./layouts/dashboards/LabDash/LabSheets/AbstractLabSheetLosses";
import DailyOpTargets from "./layouts/dashboards/LabDash/DailyOpTargets";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import LandingPage from "pages/LandingPage";
/**
  
 */
const routes = [
  /* Potentail Way to allow for user signout button on side
  {
    type: "expand",
    name: "Logout",
    key: "logout",
    route: "/LandingPage",
    component: <LandingPage />,
  },
  { type: "divider", key: "divider-0" },
  */
  {
    type: "collapse",
    name: "Home",
    key: "home",
    route: "/vfactory/signin-oidc",

    icon: <Icon fontSize="medium">Virtual Factory</Icon>,
    collapse: [
      {
        name: "Dash",
        key: "dash",
        route: "/vfactory/dash",
        component: <AbstractPage initialUnitOp="all" initialProcessLocation="None" />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
    ],
  },
  {
    type: "collapse",
    name: "Lab Sheets",
    key: "labSheets",
    route: "/vfactory/labsheets",

    icon: <Icon fontSize="medium">Virtual Factory</Icon>,
    collapse: [
      {
        name: "Facility Lab Sheet",
        key: "facilityLabSheet",
        route: "/vfactory/labsheets/facility",
        component: <AbstractLabSheet />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
      {
        name: "Beet End Lab Sheet",
        key: "beetEndLabSheet",
        route: "/vfactory/labsheets/beetEnd",
        component: <AbstractLabSheetBeetEnd />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
      {
        name: "Sugar End Lab Sheet",
        key: "sugarEndLabSheet",
        route: "/vfactory/labsheets/sugarEnd",
        component: <AbstractLabSheetSugarEnd />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
      {
        name: "Losses Lab Sheet",
        key: "lossesLabSheet",
        route: "/vfactory/labsheets/losses",
        component: <AbstractLabSheetLosses />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
    ],
  },
  {
    name: "Dash",
    key: "dash",
    route: "/vfactory/dash",
    component: <AbstractPage initialUnitOp="Diffuser" initialProcessLocation="beetEnd" />,
    icon: <Icon fontSize="medium">Virtual Factory</Icon>,
  },
  {
    type: "collapse",
    name: "WSC",
    key: "Facility",
    route: "/dashboards/Wsc/Facility",
    icon: <Icon fontSize="medium">Virtual Lab</Icon>,
    component: <AbstractPage initialUnitOp="None" initialProcessLocation="None" />,
    collapse: [
      {
        type: "collapse",
        name: "Beet End", //name =>what user see's in the list
        key: "beetend",
        icon: <Icon fontSize="small">foo icon</Icon>,
        route: "/vfactory/dash/beetend",
        component: <AbstractPage initialUnitOp="None" initialProcessLocation="beetEnd" />,
        collapse: [
          {
            name: "All Beet End",
            key: "allbeetend",
            route: "/dashboards/BeetEnd/All",
            component: <AbstractPage initialUnitOp="None" initialProcessLocation="beetEnd" />,
          },
          {
            name: "Diffuser",
            key: "diffuser",
            route: "/dashboards/BeetEnd/Diffuser",
            component: <AbstractPage initialUnitOp="Diffuser" initialProcessLocation="beetEnd" />,
          },

          {
            name: "Slicer",
            key: "Slicer",
            route: "/dashboards/BeetEnd/Slicer",
            component: <AbstractPage initialUnitOp="Slicers" initialProcessLocation="beetEnd" />,
          },
          {
            name: "Carbonation Gassing",
            key: "Carbonation-Gassing",
            route: "/dashboards/BeetEnd/Carbonation-Gassing",
            component: (
              <AbstractPage initialUnitOp="Carb Gassing" initialProcessLocation="beetEnd" />
            ),
          },
          {
            name: "Carbonation Liming",
            key: "Carbonation-Liming",
            route: "/dashboards/BeetEnd/Carbonation-Liming",
            component: (
              <AbstractPage initialUnitOp="Carb liming" initialProcessLocation="beetEnd" />
            ),
          },
          {
            name: "Enviro Clear",
            key: "Enviro-Clear",
            route: "/dashboards/BeetEnd/Enviro-Clear",
            component: (
              <AbstractPage initialUnitOp="EnviroClear" initialProcessLocation="beetEnd" />
            ),
          },
          {
            name: "Durco Filters",
            key: "Durco-Filters",
            route: "/dashboards/BeetEnd/Durco-Filters",
            component: (
              <AbstractPage initialUnitOp="Durco Filters" initialProcessLocation="beetEnd" />
            ),
          },
          {
            name: "2nd Carb Filters",
            key: "2nd-Carb-Filters",
            route: "/dashboards/SugarEnd/2nd-Carb-Filters",
            component: (
              <AbstractPage initialUnitOp="2nd Carb Filters" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Clarifier",
            key: "Clarifier",
            route: "/dashboards/BeetEnd/Clarifier",
            component: <AbstractPage initialUnitOp="Clarifier" initialProcessLocation="beetEnd" />,
          },
          {
            name: "Pulp Presses",
            key: "Pulp-Presses",
            route: "/dashboards/BeetEnd/Pulp-Presses",
            component: (
              <AbstractPage initialUnitOp="Pulp Presses" initialProcessLocation="beetEnd" />
            ),
          },
          {
            name: "Lime Kiln",
            key: "Lime-Kiln",
            route: "/dashboards/BeetEnd/Lime-Kiln",
            component: <AbstractPage initialUnitOp="Lime Kiln" initialProcessLocation="beetEnd" />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Sugar End",
        key: "Sugar-End",
        icon: <Icon fontSize="small">dashboard</Icon>,
        collapse: [
          {
            name: "All Sugar End",
            key: "allsugarend",
            route: "/dashboards/SugarEnd/All",
            component: <AbstractPage initialUnitOp="None" initialProcessLocation="sugarEnd" />,
          },
          {
            name: "2nd Carb Filters",
            key: "2nd-Carb-Filters",
            route: "/dashboards/SugarEnd/2nd-Carb-Filters",
            component: (
              <AbstractPage initialUnitOp="2nd Carb Filters" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Evaporators",
            key: "Evaporators",
            route: "/dashboards/SugarEnd/Evaporators",
            component: (
              <AbstractPage initialUnitOp="Evaporators" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Melters",
            key: "Melters",
            route: "/dashboards/SugarEnd/Melters",
            component: <AbstractPage initialUnitOp="Melters" initialProcessLocation="sugarEnd" />,
          },
          {
            name: "Standard Liquor Filters",
            key: "Standard-Liquid-Filters",
            route: "/dashboards/SugarEnd/Standard-Liquid-Filters",
            component: (
              <AbstractPage initialUnitOp="Std Liquor Filters" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Concentrator",
            key: "Concentrator",
            route: "/dashboards/SugarEnd/Concentrator",
            component: (
              <AbstractPage initialUnitOp="Concentrator" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Thick Juice Storage",
            key: "Thick-Juice-Storage",
            route: "/dashboards/SugarEnd/Thick-Juice-Storage",
            component: (
              <AbstractPage initialUnitOp="Thick Juice Stor" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "White Centrifugals",
            key: "White-Centrifugals",
            route: "/dashboards/SugarEnd/White-Centrifugals",
            component: (
              <AbstractPage initialUnitOp="White Centrifuge" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Production Scale",
            key: "Production-Scale",
            route: "/dashboards/SugarEnd/Production-Scale",
            component: (
              <AbstractPage initialUnitOp="Prod Scale" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Crystalyzer ",
            key: "Crystalizer",
            route: "/dashboards/SugarEnd/Crystallizer",
            component: (
              <AbstractPage initialUnitOp="Crystalizer" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Cooling Tower",
            key: "Cooling-Tower",
            route: "/dashboards/SugarEnd/Cooling-Tower",
            component: (
              <AbstractPage initialUnitOp="Cooling Tower" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "White Raw Pans",
            key: "White-Raw-Pans",
            route: "/dashboards/SugarEnd/white-raw-pans",
            component: (
              <AbstractPage initialUnitOp="White Raw Pans" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "High Raw Pans",
            key: "High-Raw-Pans",
            route: "/dashboards/SugarEnd/high-raw-pans",
            component: (
              <AbstractPage initialUnitOp="High Raw Pans" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Low Raw Pans",
            key: "Low-Raw-Pans",
            route: "/dashboards/SugarEnd/low-raw-pans",
            component: (
              <AbstractPage initialUnitOp="Low Raw Pans" initialProcessLocation="sugarEnd" />
            ),
          },
          {
            name: "Silos",
            key: "Silos",
            route: "/dashboards/utilities/silos",
            component: <AbstractPage initialUnitOp="Silos" initialProcessLocation="Utilities" />,
          },
          {
            name: "Conditioning Bits",
            key: "Conditioning-Bits",
            route: "/dashboards/utilities/conditioning-bits",
            component: (
              <AbstractPage initialUnitOp="Conditioning bits" initialProcessLocation="Utilities" />
            ),
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    name: "Operational Targets",
    key: "opTargs",
    route: "/vfactory/operationalTargets",
    icon: <Icon fontSize="medium">Virtual Factory</Icon>,
    collapse: [
      {
        name: "Operational Targets",
        key: "opTargs",
        route: "/vfactory/operationalTargets",
        component: <DailyOpTargets />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
    ],
  },
  /* {
    type: "collapse",
    name: "Input Logs/Slips",
    key: "inputSlips",
    route: "/vfactory/inputLogsSlips",
    icon: <Icon fontSize="medium">Virtual Factory</Icon>,
    collapse: [
      {
        name: "White Pan Slip",
        key: "whitePanSlips",
        route: "/vfactory/inputLogsSlips/whitePanSlip",
        component: <AbstractSlip />,
        icon: <Icon fontSize="medium">Virtual Factory</Icon>,
      },
    ],
  }, */
];

export default routes;
