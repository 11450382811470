const SampleLocations = [
  "1st Carb",
  "2nd Carb",
  "Aff Fillmass",
  "Affination Syr",
  "Clarifier",
  "Cooling Tower",
  "Cossette",
  "Diff Supply Water",
  "Down Thin Juice",
  "Evap Thick Juice",
  "Hi Raw Melter",
  "Hi Raw Pan",
  "Hi Raw Storage",
  "Lime Flume",
  "Low Raw",
  "Low Raw Storage",
  "Main Sewer",
  "Milk of Lime",
  "Mol Produced",
  "Pr Pulp Water",
  "PreLimer C#1",
  "PreLimer C#2",
  "PreLimer C#3",
  "PreLimer C#4",
  "PreLimer C#5",
  "PreLimer C#6",
  "Raw Jce Exit",
  "Seal Tank",
  "Std Liquor",
  "Sug Produced",
  "Thk Jce fr Stor",
  "White Pan",
  "White Sugar",
  "None",
];

export default SampleLocations;
