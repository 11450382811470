const ProcessLoc2UnitOp = {
  beetEnd: {
    unitOps: [
      "Slicers",
      "Diffuser",
      "Carb Liming",
      "Carb Gassing",
      "2nd Carb Filters",
      "Lime Kiln",
      "Clarifier",
      "Pulp Presses",
    ],
  },
  sugarEnd: {
    unitOps: [
      "Low Raw Pans",
      "Evaporators",
      "Concentrator",
      "Cooling Tower",
      "Conditioning Bins",
      "Crystalizer",
      "High Raw Pans",
      "Low Raw Pans",
      "Thick Juice Stor",
      "Std Liquor Filters",
      "White Centrifuge",
      "Whit Raw Pans",
    ],
  },
  allUnitOps: {
    unitOps: [
      "Slicers",
      "Diffuser",
      "Carb Liming",
      "Carb Gassing",
      "2nd Carb Filters",
      "Lime Kiln",
      "Clarifier",
      "Pulp Presses",
      "Low Raw Pans",
      "Evaporators",
      "Concentrator",
      "Cooling Tower",
      "Conditioning Bins",
      "Crystalizer",
      "High Raw Pans",
      "Low Raw Pans",
      "Thick Juice Stor",
      "Std Liquor Filters",
      "White Centrifuge",
      "Whit Raw Pans",
    ],
  },
};

export default ProcessLoc2UnitOp;
