// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    position: fixed;
    bottom: 5%;
    width: 100%;
  }
  
  .App {
    text-align: center;
  }
  
  .data-area-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly
  }
  
  .navbarButton {
    color: #fff !important;
    padding: .5rem 1rem !important;
  }
  
  .navbarStyle {
    padding: .5rem 1rem !important;
  }
  
  .table-responsive {
    max-height: 39rem !important;
  }
  
  .tableColumn {
    word-break: break-all
  }
  
  .todo-form {
    width: 60%;
  }
  
  .todo-list {
    width: 60%;
  }
  
  .todo-label {
    font-size: large;
    margin-right: 22%;
    margin-left: 3%;
  }
  
  .todo-view-btn {
    float: right;
  }
  
  .iconText {
    margin: 0 .5rem;
  }
  
  .profileButton {
    margin: .5rem .5rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,UAAU;IACV,WAAW;EACb;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB;EACF;;EAEA;IACE,sBAAsB;IACtB,8BAA8B;EAChC;;EAEA;IACE,8BAA8B;EAChC;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE;EACF;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["footer {\r\n    position: fixed;\r\n    bottom: 5%;\r\n    width: 100%;\r\n  }\r\n  \r\n  .App {\r\n    text-align: center;\r\n  }\r\n  \r\n  .data-area-div {\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: space-evenly\r\n  }\r\n  \r\n  .navbarButton {\r\n    color: #fff !important;\r\n    padding: .5rem 1rem !important;\r\n  }\r\n  \r\n  .navbarStyle {\r\n    padding: .5rem 1rem !important;\r\n  }\r\n  \r\n  .table-responsive {\r\n    max-height: 39rem !important;\r\n  }\r\n  \r\n  .tableColumn {\r\n    word-break: break-all\r\n  }\r\n  \r\n  .todo-form {\r\n    width: 60%;\r\n  }\r\n  \r\n  .todo-list {\r\n    width: 60%;\r\n  }\r\n  \r\n  .todo-label {\r\n    font-size: large;\r\n    margin-right: 22%;\r\n    margin-left: 3%;\r\n  }\r\n  \r\n  .todo-view-btn {\r\n    float: right;\r\n  }\r\n  \r\n  .iconText {\r\n    margin: 0 .5rem;\r\n  }\r\n  \r\n  .profileButton {\r\n    margin: .5rem .5rem;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
